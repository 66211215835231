import { AjaxLoadingModule, UserModule, ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_FILES_DASHBOARDS } from "js/constants";
import { ContextDefinitionsModule, requests as contextDefinitionsRequests } from "js/kpis/contextDefinitions";
import { requests as kpiPatternsRequests } from "js/kpis/kpiPatterns";
import _ from "lodash";
import { NodesModule } from "../node";
import { migrateDashboard } from "./migrations/migrations";
import * as mocks from "./mocks";
import { IDashboard, IDashboardEdit, convertFromDashboardEdit, sanitizeSystemGeneratedDashboards } from "./model";
import * as selectors from "./selectors";
import { actionsCreators } from "./slices";

const getImportedDefaultDashboards = () => {
    if (process.env.EXEC_MODE == "icp") {
        return import("../../../generated/defaultDashboards-icp").then((systemGeneratedDashboards) => (
            sanitizeSystemGeneratedDashboards(systemGeneratedDashboards.default)
        ));
    } else {
        return import("../../../generated/defaultDashboards").then((systemGeneratedDashboards) => (
            sanitizeSystemGeneratedDashboards(systemGeneratedDashboards.default)
        ));
    }
};

/**
 * Returns all dashboards. Combination of custom dashboards created by the user and system dashboards generated dynamically.
 */
export const getDashboardsThrottled = getThrottleAjax((millNodeId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (_.size(getState().dashboards) > 0) return getState().dashboards;
            return mocks.getDashboards(millNodeId);
        },
        url: `${API_PREFIX_FILES_DASHBOARDS}?files&millId=${millNodeId}`, // Need millId for browser cache consideration
        onSuccess: (customDashboards: IDashboard[]) => (
            Promise.all([
                getImportedDefaultDashboards(),
                dispatch(contextDefinitionsRequests.getAllContextDefinitions(millNodeId)).promise,
                dispatch(kpiPatternsRequests.getKpiPatternsThrottled(millNodeId)).promise,
            ]).then(([importedDefaultDashboards]) => {
                const systemGeneratedDashboards = _.mapValues(selectors.getSystemDashboards(getState(), importedDefaultDashboards), (d) => ({ ...d, millNodeId })); // inject the current mill id

                const usersDashboards = _.chain(customDashboards)
                    .keyBy(({ id }) => id)
                    // FIXME: the back should filter them out but it's not the case so we must filter them out ourself, we need to be careful to not filter out unmigrated dashboards.
                    .pickBy((d) => (process.env.EXEC_MODE == "cmoc" && !d.millNodeId) || d.millNodeId == millNodeId)
                    .value();

                const allDashboards = { ...usersDashboards, ...systemGeneratedDashboards };
                dispatch(actionsCreators.set(allDashboards));
            }).catch((e) => {
                console.error(e);
            })
        ),
    })
), 10 * 1000);

export const saveDashboard = (anyDashboard: IDashboard | IDashboardEdit) => (dispatch: IStoreDispatch) => {
    const dashboard = convertFromDashboardEdit(anyDashboard);
    const isNew = dashboard.id.startsWith("new");

    const id = (() => {
        if (process.env.EXEC_MODE == "icp" && isNew) return null;
        return isNew ? Date.now().toString() : dashboard.id;
    })();

    const newDashboard: IDashboard = {
        ...dashboard,
        id,
        modificationDate: Date.now(),
        isSystem: false,
        duplicatedId: null,
    };
    return ajaxRequest({
        method: (process.env.EXEC_MODE == "icp" && !isNew) ? "PUT" : "POST",
        data: newDashboard,
        serverLessResolve: () => newDashboard,
        url: id ? `${API_PREFIX_FILES_DASHBOARDS}/${id}` : `${API_PREFIX_FILES_DASHBOARDS}`,
        showAjaxLoading: true,
        onSuccess: ((serverDashboard?: IDashboard) => {
            const dashboardToSave = process.env.EXEC_MODE == "icp" ? serverDashboard : newDashboard;
            dispatch(actionsCreators.patch([dashboardToSave]));
            return dashboardToSave;
        }),
    }).promise;
};

export const uploadDashboard = getAction((dashboard: IDashboard) => async (dispatch, getState): Promise<string[]> => {
    const UPLOAD_DASHBOARD = "UPLOAD_DASHBOARD";
    await dispatch(AjaxLoadingModule.requests.showAjaxLoading({ id: UPLOAD_DASHBOARD }));
    const currentMillNodeId = NodesModule.selectors.getCurrentMillNodeId(getState());
    const updatedDashboard = migrateDashboard(dashboard, currentMillNodeId);

    const nodesOfMill = NodesModule.selectors.getNodesChildrenOfTypeRecursive(getState(), currentMillNodeId, null);

    const injectedDashboards = (
        _.map(selectors.createInjectedIdsDashboard(updatedDashboard, nodesOfMill, ContextDefinitionsModule.selectors.getContextDefinitions(getState())), (d) => ({
            ...d,
            id: `new_${Date.now()}`,
        }))
    );

    const saveDashboardPromises = _.map(injectedDashboards, (d) => dispatch(saveDashboard(d)));
    const savedDashboards = await Promise.all(saveDashboardPromises);
    await dispatch(AjaxLoadingModule.requests.hideAjaxLoading(UPLOAD_DASHBOARD));
    return _.map(savedDashboards, (d) => d.id);
});

export const convertOldDashboard = getAction((dashboardId: string) => async (dispatch, getState): Promise<string[]> => {
    const MIGRATE_DASHBOARD = "MIGRATE_DASHBOARD";
    await dispatch(AjaxLoadingModule.requests.showAjaxLoading({ id: MIGRATE_DASHBOARD }));
    const currentMillNodeId = NodesModule.selectors.getCurrentMillNodeId(getState());
    const oldDashboard = selectors.getDashboardById(getState(), dashboardId);
    const updatedDashboard = migrateDashboard(oldDashboard, currentMillNodeId);
    const nodesOfMill = NodesModule.selectors.getNodesChildrenOfTypeRecursive(getState(), currentMillNodeId, null);
    const injectedDashboards = selectors.createInjectedIdsDashboard(updatedDashboard, nodesOfMill, ContextDefinitionsModule.selectors.getContextDefinitions(getState()));
    const saveDashboardPromises = _.map(injectedDashboards, (d) => dispatch(saveDashboard(d)));
    const savedDashboards = await Promise.all(saveDashboardPromises);
    await dispatch(AjaxLoadingModule.requests.hideAjaxLoading(MIGRATE_DASHBOARD));
    return _.map(savedDashboards, (d) => d.id);
});

export const duplicateDashboard = (dashboardId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    dispatch(saveDashboard({
        ...selectors.getDashboardById(getState(), dashboardId),
        id: "new_" + Date.now().toString(),
        userId: UserModule.selectors.getCurrentUser(getState()).id,
        isSystem: false,
    }))
);

export const deleteDashboard = (id: string) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "DELETE",
        serverLessResolve: () => null,
        url: `${API_PREFIX_FILES_DASHBOARDS}/${id}`,
        showAjaxLoading: true,
        onSuccess: (() => {
            dispatch(actionsCreators.delete([id]));
        }),
    }).promise
);