import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../node";
import { t } from "@comact/crc";

export const getDowntimes = ({ downtimes }: IStoreState) => downtimes;
export const getAllNodeIdsWithDowntimeSupport = ({ nodeIdsWithDowntimeSupport }: IStoreState) => nodeIdsWithDowntimeSupport;
export const getAllNodeIdsWithDowntimeSupportForMill = memoize(({ state, millNodeId }: { state: IStoreState; millNodeId: string; }) => (
    _.filter(state.nodeIdsWithDowntimeSupport, (id) => !millNodeId || NodesModule.selectors.getNodeFirstParentOfType(state, millNodeId, "millCodec").id != id)
));
export const getDowntimeNodeName = (state: IStoreState, nodeId: string) => {
    const node = NodesModule.selectors.getNodeById(state, nodeId);
    if (!node) return t("downtimes.unknownNode"); // a node might be deleted but the downtime still uses the id
    return node.templateName == "machineCodec"
        ? NodesModule.selectors.getNodeNamePath(state, node.id, process.env.EXEC_MODE == "icp")
        : node.name; // we don't want the full path for plc nodes because they are deep in the hierarchy
};
export const getAllDowntimeComments = ({ downtimeComments }: IStoreState) => downtimeComments;
export const getDowntimeCommentById = memoize(({ state, commentId }: { state: IStoreState; commentId: string; }) => _.find(getAllDowntimeComments(state), ({ id }) => id == commentId));
export const getDowntimeCommentsByCodeAndNodeId = memoize(({ state, code, selectedNodeId }: { state: IStoreState; code: number; selectedNodeId: string; }) => (
    getAllDowntimeComments(state)
        ? _.chain(getAllDowntimeComments(state))
            .filter(({ downtimeCode, nodeId }) => code == downtimeCode && nodeId == selectedNodeId)
            .keyBy(({ id }) => id)
            .value()
        : null
));

export const getDowntimesByNodeId = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => {
    const node = NodesModule.selectors.getNodeById(state, nodeId);
    if (!node) return {};
    const allDowntimes = getDowntimes(state);
    switch (node.templateName) {
        case "millCodec": return allDowntimes;
        case "machineCodec": return _.pickBy(allDowntimes, (d) => d.nodeId == nodeId);
        default: return {};
    }
};

export const getDowntimeById = memoize(({ state, id }: { state: IStoreState; id: string; }) => getDowntimes(state)?.[id]);

export const getSelectedDowntimesNodeIds = memoize(({ state, selectedDowntimeIds }: { state: IStoreState; selectedDowntimeIds: string[]; }) => (
    _.chain(selectedDowntimeIds)
        .map((id) => getDowntimeById({ state, id }).nodeId)
        .uniq()
        .value()
));
