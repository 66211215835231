// eslint-disable-next-line no-restricted-imports
import { type Icon, IMenuItem, IPermission, ISubMenuItemRendererProps, store } from "@comact/crc";
import _ from "lodash";
import DashboardMenu from "../../dashboards/components/DashboardMenu";
import { IFeatureFlagTypes } from "../../featureFlags/model";
import { ITemplate } from "../../nodeTemplate/model";
import { INode } from "../model";

export const perspectivePositions = ["header", "menu", "overview"] as const;

export type IPerspectivePosition = typeof perspectivePositions[number];

export type IPerspective = "backups" | "backups/activation-history" | "backups/application-logs" | "backups/service-backups" | "backups/system-backups" | "camera-list" |
    "cloud-files" | "cloudAgent" | "cloudAgent-files-pending" | "cloudAgent-stats" | "cmoc-backups-restore" | "dashboards" | "dashboards/edit" | "dashboards/events" | "dashboards/logs" |
    "details" | "details/edit" | "details/editLinks" | "documentation" | "events" | "events/search-pick-and-place-events" | "events/timeline" | "events/alerts" |
    "events/downtime-app" | "events/downtime-app/downtimes" | "events/downtime-app/downtimes/edit" | "events/downtime-app/watchlist" | "events/downtime-app/watchlist/edit" |
    "link" | "login" | "models" | "models/activations" | "models/events" | "my-preferences" | "notifications" | "notifications/grafana-alerts" | "notifications/grafana-alerts/edit" |
    "notifications/notification-groups" | "notifications/notification-groups/edit" | "overview" | "organization-settings" | "organization-settings/grafana-integration" | "optimizer-configurations" |
    "parameters" | "parameters/batches" | "parameters/batches/edit" | "parameters/data-io" | "parameters/contexts" | "parameters/contexts/edit" |
    "parameters/intervals" | "parameters/intervals/edit" | "parameters/kpi-alerts" | "parameters/kpi-alerts/edit" | "parameters/kpis" | "parameters/kpis/edit" |
    "parameters/labels" | "parameters/labels/bin" | "parameters/labels/bin/edit" | "parameters/labels/color-mark" | "parameters/labels/color-mark/edit" |
    "parameters/labels/correction-error" | "parameters/labels/correction-error/edit" | "parameters/labels/downgrade" | "parameters/labels/downgrade/edit" |
    "parameters/labels/downtime" | "parameters/labels/downtime/edit" | "parameters/labels/species" | "parameters/labels/species/edit" |
    "parameters/label-groups" | "parameters/label-groups/bin" | "parameters/label-groups/bin/edit" | "parameters/label-groups/color-mark" | "parameters/label-groups/color-mark/edit" |
    "parameters/label-groups/correction-error" | "parameters/label-groups/correction-error/edit" | "parameters/label-groups/downgrade" | "parameters/label-groups/downgrade/edit" |
    "parameters/label-groups/downtime" | "parameters/label-groups/downtime/edit" | "parameters/label-groups/species" | "parameters/label-groups/species/edit" |
    "parameters/reports/configurations" | "parameters/reports/configuration/edit" | "parameters/reports/jobs" | "parameters/reports/jobs/edit" |
    "parameters/species-density" | "parameters/species-density/edit" | "parameters/tag-strategies" | "parameters/tag-strategies/editor" | "reports" |
    "resource-library" | "resource-library/user-manuals" | "resource-library/service-reports" | "resource-library/technical-drawings" | "resource-library/maintenance-manuals" |
    "schedule" | "schedule/edit" | "sitemap" | "templates" | "third-party-integration" | "user-events" | "user-management" | "user-management/events/groups" |
    "user-management/groups" | "user-management/groups/edit" | "user-management/users" | "user-management/users/edit" | "zone-configuration" | "zone-configuration-advanced";

export interface IPerspectiveDefinition {
    id: IPerspective;
    parent: IPerspective; // The parent perspective, to create menu hierarchy. Ideally of the type IPerspective but that makes circular references.
    templates: ITemplate["name"][]; // Templates that have access to this perspective. Null indicates that the perspective is available from any places.
    systemPerspective?: boolean; // Activate this property if the perspective is a system perspective (not a node perspective)
    perspectivePosition: IPerspectivePosition[]; // Where the perspective can be shown
    permissions: IPermission[]; // List of all needed permissions to use this perspective
    featureFlags: IFeatureFlagTypes[]; // List of the feature flags needed to use this perspective
    conditions?: ("account" | "user")[] | null;
    validPlatform: ("cmoc" | "icp")[]; // List of valid platforms
    priority?: number; // The order of the perspective in the menu. Perspectives without any priority come first and are sorted has they comes in the definition.
    hideInSubmenu?: boolean; // If this perspective must be hide in submenu
    hidden?: () => boolean; // Special case to indicates that this perspective must be hidden
    runWithoutNodes?: boolean; // Does it need to run without any nodes (not iep machines) false by default
    customSubMenuRenderer?: React.NamedExoticComponent<ISubMenuItemRendererProps>;
}

// An instance of a perspective (link) for a specific node
export interface IPerspectiveInstance extends IMenuItem {
    id: IPerspective | string;
    perspectiveId: IPerspective;
    perspectiveParentId: IPerspective;
    icon: React.ComponentPropsWithoutRef<typeof Icon>["type"];
    isAlternate: boolean;
}

const allPerspectivesDefinitionsArray: IPerspectiveDefinition[] = [
    {
        id: "overview",
        parent: null,
        templates: ["millCodec", "customer", "location"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
        runWithoutNodes: true,
    },
    {
        id: "dashboards",
        parent: null,
        templates: ["millCodec"],
        perspectivePosition: ["header", "overview"],
        permissions: [],
        featureFlags: ["Dashboard"],
        validPlatform: ["cmoc", "icp"],
        customSubMenuRenderer: DashboardMenu,
    },
    {
        id: "schedule",
        parent: null,
        templates: ["millCodec"],
        perspectivePosition: ["header", "overview"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "schedule/edit",
        parent: "schedule",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["schedule-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "reports",
        parent: null,
        templates: ["millCodec"],
        perspectivePosition: ["header", "overview"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "login",
        parent: null,
        templates: null,
        systemPerspective: true,
        perspectivePosition: [],
        permissions: null,
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc"],
    },
    {
        id: "my-preferences",
        parent: null,
        templates: ["millCodec"],
        systemPerspective: true,
        perspectivePosition: [],
        permissions: [],
        featureFlags: [],
        conditions: ["user"],
        hideInSubmenu: true,
        validPlatform: ["cmoc", "icp"],
        priority: 10,
    },
    {
        id: "user-management",
        parent: null,
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["user-management"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc", "icp"],
        priority: 10,
    },
    {
        id: "user-management/users",
        parent: "user-management",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["user-management"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "user-management/users/edit",
        parent: "user-management/users",
        templates: null,
        systemPerspective: true,
        perspectivePosition: [],
        permissions: ["user-management"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "user-management/groups",
        parent: "user-management",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["user-management"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "user-management/groups/edit",
        parent: "user-management/groups",
        templates: null,
        systemPerspective: true,
        perspectivePosition: [],
        permissions: ["user-management"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "user-management/events/groups",
        parent: "user-management/groups",
        templates: [],
        systemPerspective: true,
        perspectivePosition: [],
        permissions: ["user-management", "user-events"],
        featureFlags: [],
        validPlatform: [], // Disabled for now (ICP-2208)
    },
    {
        id: "templates",
        parent: null,
        templates: null, // Available from any places
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["system-debug"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "notifications",
        parent: null,
        templates: null,
        perspectivePosition: ["menu"],
        permissions: ["alert-management"],
        featureFlags: [],
        systemPerspective: true,
        validPlatform: ["icp"],
        hideInSubmenu: true,
    },
    {
        id: "notifications/grafana-alerts",
        parent: "notifications",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["alert-management"],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "notifications/grafana-alerts/edit",
        parent: "notifications/grafana-alerts",
        templates: null,
        systemPerspective: true,
        perspectivePosition: [],
        permissions: ["alert-management"],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "notifications/notification-groups",
        parent: "notifications",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["alert-management"],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "notifications/notification-groups/edit",
        parent: "notifications/notification-groups",
        templates: null,
        systemPerspective: true,
        perspectivePosition: [],
        permissions: ["alert-management"],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "third-party-integration",
        parent: null,
        templates: ["location", "millCodec"],
        perspectivePosition: ["menu"],
        permissions: ["api-keys"],
        featureFlags: ["RawData"],
        validPlatform: ["icp"],
        hideInSubmenu: true,
    },
    {
        id: "organization-settings",
        parent: null,
        templates: null,
        perspectivePosition: ["menu"],
        permissions: [],
        featureFlags: [],
        systemPerspective: true,
        validPlatform: ["icp"],
        hideInSubmenu: true,
    },
    {
        id: "organization-settings/grafana-integration",
        parent: "organization-settings",
        templates: null,
        perspectivePosition: ["menu"],
        permissions: [],
        featureFlags: [],
        systemPerspective: true,
        validPlatform: ["icp"],
    },
    {
        id: "dashboards/edit",
        parent: "dashboards",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["dashboards-edit-all"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "dashboards/events",
        parent: "dashboards",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["user-events"],
        featureFlags: [],
        validPlatform: [], // Disabled for now (ICP-2208)
    },
    {
        id: "parameters/reports/jobs",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["report-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/reports/jobs/edit",
        parent: "parameters/reports/jobs",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["report-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/reports/configurations",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["report-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/reports/configuration/edit",
        parent: "parameters/reports/configurations",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["report-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "events",
        parent: null,
        templates: ["millCodec"],
        systemPerspective: false,
        perspectivePosition: ["header"],
        permissions: ["system-events"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc"],
    },
    {
        id: "events/timeline",
        parent: "events",
        templates: ["millCodec"],
        systemPerspective: false,
        perspectivePosition: ["header"],
        permissions: ["system-events"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc"],
    },
    {
        id: "events/search-pick-and-place-events",
        parent: "events",
        templates: ["millCodec"],
        systemPerspective: false,
        perspectivePosition: ["header"],
        permissions: ["system-events"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc"],
        hidden: () => !_.some(store.getState().nodes, (node) => node.templateName == "pickAndPlace"),
    },
    {
        id: "events/alerts",
        parent: "events",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["system-events"],
        featureFlags: [],
        priority: 1,
        validPlatform: ["cmoc"],
    },
    {
        id: "backups",
        parent: null,
        templates: [process.env.EXEC_MODE == "icp" ? "location" : "millCodec"],
        hideInSubmenu: true,
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp", "cmoc"],
    },
    {
        id: "backups/system-backups",
        parent: "backups",
        templates: [process.env.EXEC_MODE == "icp" ? "location" : "millCodec"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp", "cmoc"],
    },
    {
        id: "backups/activation-history",
        parent: "backups",
        templates: [process.env.EXEC_MODE == "icp" ? "location" : "millCodec"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp", "cmoc"],
    },
    {
        id: "backups/application-logs",
        parent: "backups",
        templates: [process.env.EXEC_MODE == "icp" ? "location" : "millCodec"],
        perspectivePosition: ["header"],
        permissions: ["system-debug"],
        featureFlags: [],
        validPlatform: ["icp", "cmoc"],
    },
    {
        id: "backups/service-backups",
        parent: "backups",
        templates: [process.env.EXEC_MODE == "icp" ? "location" : "millCodec"],
        perspectivePosition: ["header"],
        permissions: ["system-debug"],
        featureFlags: [],
        validPlatform: ["icp", "cmoc"],
    },
    {
        id: "parameters",
        parent: null,
        templates: ["millCodec", "genericDevice"],
        perspectivePosition: ["header"],
        hideInSubmenu: true,
        permissions: ["system-config-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/intervals",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/intervals/edit",
        parent: "parameters/intervals",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels",
        parent: "parameters",
        templates: ["millCodec", "genericDevice"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/downgrade",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/downtime",
        parent: "parameters/labels",
        templates: ["millCodec", "genericDevice"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/bin",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/correction-error",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/species",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/color-mark",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/downgrade/edit",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/downtime/edit",
        parent: "parameters/labels",
        templates: ["millCodec", "genericDevice"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/bin/edit",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/correction-error/edit",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/species/edit",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/labels/color-mark/edit",
        parent: "parameters/labels",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/downgrade",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/downtime",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/bin",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/correction-error",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/species",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/color-mark",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/downgrade/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/downtime/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/bin/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/correction-error/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/species/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/label-groups/color-mark/edit",
        parent: "parameters/label-groups",
        templates: ["millCodec", "machineCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/kpis",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["kpi-edit"],
        featureFlags: [],
        priority: 1,
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/kpis/edit",
        parent: "parameters/kpis",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["kpi-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/contexts",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["kpi-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/contexts/edit",
        parent: "parameters/contexts",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["kpi-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/data-io",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/kpi-alerts",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["kpi-edit"],
        featureFlags: [],
        priority: 1,
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/kpi-alerts/edit",
        parent: "parameters/kpi-alerts",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["kpi-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/species-density",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/species-density/edit",
        parent: "parameters/species-density",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/tag-strategies",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/tag-strategies/editor",
        parent: "parameters/tag-strategies",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "parameters/batches",
        parent: "parameters",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !store.getState().batchConfig?.batchProcessingEnabled,
    },
    {
        id: "parameters/batches/edit",
        parent: "parameters/batches",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["production-data-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !store.getState().batchConfig?.batchProcessingEnabled,
    },
    {
        id: "details",
        parent: null,
        templates: null,
        perspectivePosition: ["header"],
        permissions: ["system-config-get"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "details/edit",
        parent: "details",
        templates: null,
        perspectivePosition: [],
        permissions: ["system-config-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "details/editLinks",
        parent: "details",
        templates: null,
        perspectivePosition: [],
        permissions: ["node-links-edit"],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "cloud-files",
        parent: null,
        templates: ["L5X"],
        perspectivePosition: ["header"],
        permissions: null,
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "user-events",
        parent: null,
        templates: null, // Available from any places
        systemPerspective: true,
        perspectivePosition: ["menu", "overview"],
        permissions: ["user-events"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: [], // Disabled for now (ICP-2208)
    },
    {
        id: "models",
        parent: null,
        templates: null, // Available from any places
        systemPerspective: false,
        perspectivePosition: ["menu", "overview"],
        permissions: ["model-edit"],
        featureFlags: [],
        validPlatform: [],
    },
    {
        id: "models/events",
        parent: "models",
        templates: null, // Available from any places
        systemPerspective: false,
        perspectivePosition: [],
        permissions: ["model-edit", "user-events"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: [], // Disabled for now (ICP-2208)
    },
    {
        id: "models/activations",
        parent: "models",
        templates: null, // Available from any places
        systemPerspective: false,
        perspectivePosition: ["menu"],
        permissions: ["system-config-edit"],
        featureFlags: [],
        hideInSubmenu: false,
        validPlatform: [], // FIXME: ICP-878 Réactiver le model-manager sur les machines cmoc
    },
    {
        id: "cmoc-backups-restore", // iframe icp to download a cloud backup into cmoc
        parent: null,
        templates: ["machineCodec"],
        perspectivePosition: [],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "link",
        parent: null,
        templates: null,
        perspectivePosition: ["header", "overview"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc", "icp"],
    },
    {
        id: "camera-list",
        parent: null,
        templates: ["cameraServer"],
        perspectivePosition: ["header"],
        permissions: ["system-config-get"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        runWithoutNodes: false,
    },
    {
        id: "cloudAgent",
        parent: null,
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["system-debug"],
        featureFlags: [],
        hideInSubmenu: true,
        validPlatform: ["cmoc"],
        runWithoutNodes: false,
        priority: 20,
        hidden: () => !store.getState().system?.cloudEnabled,
    },
    {
        id: "cloudAgent-stats",
        parent: "cloudAgent",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["system-debug"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        runWithoutNodes: false,
        hidden: () => !store.getState().system?.cloudEnabled,
    },
    {
        id: "cloudAgent-files-pending",
        parent: "cloudAgent",
        templates: null,
        systemPerspective: true,
        perspectivePosition: ["menu"],
        permissions: ["system-debug"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        runWithoutNodes: false,
        hidden: () => !store.getState().system?.cloudEnabled,
    },
    {
        id: "events/downtime-app",
        parent: "events",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !_.find(store.getState().services, ({ name }) => name == "cmoc.services.downtimeApp"),
    },
    {
        id: "events/downtime-app/downtimes",
        parent: "events/downtime-app",
        templates: ["millCodec"],
        perspectivePosition: ["overview", "header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !_.find(store.getState().services, ({ name }) => name == "cmoc.services.downtimeApp"),
    },
    {
        id: "events/downtime-app/downtimes/edit",
        parent: "events/downtime-app/downtimes",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: [],
        featureFlags: [],
        validPlatform: [], // O8-47 was for a demo, this is disable since it's only working with mocks
    },
    {
        id: "events/downtime-app/watchlist",
        parent: "events/downtime-app",
        templates: ["millCodec"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !_.find(store.getState().services, ({ name }) => name == "cmoc.services.downtimeApp"),
    },
    {
        id: "events/downtime-app/watchlist/edit",
        parent: "events/downtime-app/watchlist",
        templates: ["millCodec"],
        perspectivePosition: [],
        permissions: ["system-config-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
        hidden: () => !_.find(store.getState().services, ({ name }) => name == "cmoc.services.downtimeApp"),
    },
    {
        id: "zone-configuration",
        parent: null,
        templates: ["smartVisionZone"],
        perspectivePosition: [],
        permissions: ["system-config-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "zone-configuration-advanced",
        parent: null,
        templates: ["smartVisionZone"],
        perspectivePosition: [],
        permissions: ["system-config-edit"],
        featureFlags: [],
        validPlatform: ["cmoc"],
    },
    {
        id: "resource-library",
        parent: null,
        templates: ["location"],
        perspectivePosition: ["header"],
        permissions: [],
        hideInSubmenu: true,
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "resource-library/user-manuals",
        parent: "resource-library",
        templates: ["location"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "resource-library/service-reports",
        parent: "resource-library",
        templates: ["location"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "resource-library/technical-drawings",
        parent: "resource-library",
        templates: ["location"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp"],
    },
    {
        id: "resource-library/maintenance-manuals",
        parent: "resource-library",
        templates: ["location"],
        perspectivePosition: ["header"],
        permissions: [],
        featureFlags: [],
        validPlatform: ["icp"],
    },
];

export const allPerspectivesDefinitions = _.keyBy(allPerspectivesDefinitionsArray, ({ id }) => id) as Record<IPerspective, IPerspectiveDefinition>;

// FIXME: to revalidate if we can remove some more (only used for cameras)
export const overviewAllowedTemplates: ITemplate["name"][] = ["millCodec", "customer", "location", "cameraServer", "camera", "standaloneCamera"];

/**
 * Return the asked perpective for a node if it's available, otherwise, return an available fallback.
 */
export const getNodePerspectiveOrFallback = (nodeTemplate: INode["templateName"], askPerspective: IPerspective): IPerspective => {
    if (askPerspective == allPerspectivesDefinitions.details.id) return askPerspective; // The "details" perspective is always available, no need for more logic

    const fallbackNodeTemplateNames: { [key in INode["templateName"]]?: IPerspective; } = { // Fallback definitions for special nodes
        cameraServer: process.env.EXEC_MODE == "icp" ? null : "camera-list", // we can't see the cameras videos on icp for now
    };

    if (allPerspectivesDefinitions[askPerspective].templates?.includes(nodeTemplate)) return askPerspective; // The requested perpective is available for this node template
    else if (fallbackNodeTemplateNames?.[nodeTemplate]) return fallbackNodeTemplateNames?.[nodeTemplate]; // Check if there is a fallback perspective
    else if (allPerspectivesDefinitions.overview.templates?.includes(nodeTemplate)) return allPerspectivesDefinitions.overview.id; // Check if we can fallback on the overview perspective
    return allPerspectivesDefinitions.details.id; // The ultimate fallback perspective => "details", wich it good for every node
};