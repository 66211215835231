import { getLocalizedText, ILocalizedString } from "@comact/crc/index";
import { CUSTOM_MUNIT, IMUnit, IMUnitsState } from "./model";

export const getUnits = ({ mUnits }: IStoreState) => mUnits;

const getUnit = (mUnits: IMUnitsState, unitId: string): IMUnit => mUnits?.units?.[unitId];
const getUnitLabel = (mUnits: IMUnitsState, unitId: string) => getUnit(mUnits, unitId)?.unitLabel || "";
const getUnitSymbol = (mUnits: IMUnitsState, unitId: string) => getUnit(mUnits, unitId)?.unitSymbol || "";

export const getUnitDisplayLabel = (mUnits: IMUnitsState, unitId: string, unitLabel: ILocalizedString) => (
    unitId == CUSTOM_MUNIT ? getLocalizedText(unitLabel) : getUnitLabel(mUnits, unitId)
);

export const getUnitDisplaySymbol = (mUnits: IMUnitsState, unitId: string, unitLabel: ILocalizedString) => (
    unitId == CUSTOM_MUNIT ? getLocalizedText(unitLabel) : getUnitSymbol(mUnits, unitId)
);
